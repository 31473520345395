<template>
    <div>
        <div v-if="successMessage" class="alert alert-success" style="color: rgb(5, 20, 48)">
            {{ successMessage }}
        </div>
        <b-card>
            <b-row>
                <div class="col-12 mt-16">
                    <b-form @submit.prevent="addUser" v-if="show">
                        <div style="
                background-color: rgb(97, 116, 152);
                height: 32px;
                border-radius: 4px;
              ">
                            <h4 style="
                  color: rgb(223, 227, 238);
                  margin-left: 5px;
                  font-weight: bold;
                ">
                                Slider Information
                            </h4>
                        </div>
                        <div class="row">
                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="URL:" label-for="url">
                                    <b-form-input id="url" type="url" placeholder="Enter URL" v-model="url" required>
                                    </b-form-input>
                                </b-form-group>
                            </div>
                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="Profile Picture:" label-for="image">
                                    <div style="margin-left: 3px; margin-bottom: 15px">
                                        <input type="file" accept="image/*" id="image" @change="onProfilePictureChange"
                                            required />
                                    </div>
                                </b-form-group>
                            </div>

                        </div>

                        <!-- Bank Information End -->
                        <b-button type="submit" variant="primary" class="mb-8 mr-8" :disabled="isLoading">
                            <span v-if="!isLoading">Submit</span>
                            <b-spinner v-else class="mb-8 mr-8" variant="primary" small></b-spinner>
                        </b-button>
                    </b-form>
                </div>
                <div v-if="codeActive" class="col-12 mt-24 hljs-container" :class="{ active: codeActiveClass }">
                    <pre v-highlightjs>
          <code class="hljs html">
            {{ codeText }}
          </code>
        </pre>
                </div>
            </b-row>
        </b-card>
    </div>
</template>

<script>
import {
    BRow,
    BCol,
    BCard,
    BButton,
    BForm,
    BFormGroup,
    BFormCheckboxGroup,
    BFormCheckbox,
    BFormSelect,
    BFormInput,
    BSpinner,
} from "bootstrap-vue";
import axios from "axios";
import { BToast } from "bootstrap-vue";
import code from "@/view/components/data-entry/form/code";

export default {
    data() {
        return {
            select_vehicle_type: "",
            selectedImage: null,
            show: true,
            codeText: code.introduction,
            codeActive: false,
            codeActiveClass: false,
            selectedOption: "",
            fixSalary: "",
            inputField1: "",
            inputField2: "",
            inputField3: "",
            showModal: false,
            isLoading: false,
            image: "",
            url: "",


        };
    },
    components: {
        BRow,
        BCol,
        BCard,
        BButton,
        BForm,
        BFormGroup,
        BFormCheckboxGroup,
        BFormCheckbox,
        BFormSelect,
        BFormInput,
        BToast, // Add this line
        BSpinner,
    },

                methods: {

            showMsgBoxOne() {
                debugger;
            },
            onSubmit(event) {
                event.preventDefault();
                alert(JSON.stringify(this.form));
                // debugger
            },

            addUser() {
                this.isLoading = true;
                // Create a FormData object to handle the image file
                const formData = new FormData();
                formData.append("image", this.image);
                formData.append("url", this.url);
                axios

                    .post("sliderStore", formData)
                    .then((response) => {
                        console.log(response.data);
                        this.$bvToast.toast("Slider added successfully!", {
                            title: "Success",
                            variant: "success",
                            solid: true,
                            appendToast: true,
                            toaster: "b-toaster-top-right",
                            autoHideDelay: 5000,
                            variant: "primary", // Background color
                        });
                        this.isLoading = false;
                        // this.$router.push({ name: 'ListSlider' });
                    })
                    .catch((error) => {
                        this.errors = error.response.data.errors;
                        console.log(error.response.data);
                        this.isLoading = false;
                    });
            },
            onProfilePictureChange(event) {
                const file = event.target.files[0];
                if (file) {
                    this.image = file;
                }
            },

            codeClick() {
                this.codeActive = !this.codeActive;


            },

            saveOwnCar() {
                this.showModal = false;
            },
            },
};
</script>
